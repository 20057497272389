/* src/components/Contact.css */
body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
  }
  
  .contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    min-height: 100vh; /* Ensure the page covers full height */
  }
  
  .map-container {
    width: 100%;
    margin-top: 20px;
  }
  
  .contact-sections {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    max-width: 1200px;
    width: 100%;
  }
  
  .feedback-form,
  .contact-info {
    flex: 1 1 48%; /* Allow sections to take up almost half the width */
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 10px;
  }
  
  .feedback-form h2,
  .contact-info h2 {
    color: #993366;
    margin-bottom: 10px;
  }
  
  .feedback-form form {
    display: flex;
    flex-direction: column;
  }
  
  .feedback-form label {
    margin-bottom: 10px;
  }
  
  .feedback-form input,
  .feedback-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .feedback-form button {
    padding: 10px;
    background-color: #993366;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .feedback-form button:hover {
    background-color: #7a2a50;
  }
  
  @media (max-width: 768px) {
    .feedback-form,
    .contact-info {
      flex: 1 1 100%; /* Take full width on mobile */
      margin: 10px 0;
    }
  
    .contact-sections {
      flex-direction: column;
      align-items: center;
    }
  }
  