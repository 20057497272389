/* src/components/Navbar.css */
.navbar {
  display: flex;
  
  position: absolute;
  top: 20px;
  right: 20px;
  transition: transform 0.3s ease-in-out;
  z-index: 2; /* Ensure the navbar is above the background and overlay */
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: 300; /* Light version */
  font-size: 15px;
}

.menu-icon,
.close-icon {
  
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 10; /* Ensure the icons are above the background and overlay */
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    top: 0;
    right: 0;
    height: 100%;
    width: 200px;
    background-color: #330019;
    position: fixed;
    transform: translateX(100%);
  }

  .navbar.open {
    transform: translateX(0);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .close-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
