/* src/components/StickyHeader.css */
.sticky-header {
  width: 100%;
  background-color: #993366;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 0.3s ease;
  
}

.header-large .header-content {
  padding: 20px;
}

.header-small .header-content {
  padding: 10px; /* Reduce padding on scroll */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header-logo {
  width: 250px; /* Set a consistent size */
  height: auto;
}

.navbar {
  display: flex;
  gap: 20px;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: 300; /* Light version */
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px; /* Adjust padding */
  }

  .header-logo {
    width: 150px; /* Smaller logo for mobile */
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
