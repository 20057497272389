/* src/components/Company.css */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.company-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
  min-height: 100vh; /* Ensure the page covers full height */
}

.company-content {
  max-width: 800px;
  width: 100%;
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 100px; /* Space from the header */
}

.company-content h1, .company-content h4 {
  color: #993366;
  margin-top: 20px;
}

.company-content p, .company-content ul {
  margin: 10px 0;
  line-height: 1.6;
}

.company-content ul {
  padding-left: 20px;
  list-style-type: disc;
}

@media (max-width: 768px) {
  .company-content {
    padding: 15px;
  }
}
