/* src/components/Disclaimer.css */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.disclaimer-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
  min-height: 100vh; /* Ensure the page covers full height */
}

.sticky-header {
  width: 100%;
  background-color: #333;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 0.3s ease;
}

.header-large .header-content {
  padding: 20px;
}

.header-small .header-content {
  padding: 10px; /* Reduce padding on scroll */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header-logo {
  width: 120px; /* Set a consistent size */
  height: auto;
}

.navbar {
  display: flex;
  gap: 20px;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: 300; /* Light version */
}

.disclaimer-content {
  max-width: 90%;
  width: 100%;
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 100px; /* Space from the header */
}

.disclaimer-content h1, .disclaimer-content h4 {
  color: #993366;
  margin-top: 20px;
}

.disclaimer-content p {
  margin: 10px 0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px; /* Adjust padding */
  }

  .header-logo {
    width: 100px; /* Smaller logo for mobile */
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .disclaimer-content {
    padding: 15px;
  }
}
