/* src/components/Home.css */
body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300; /* Light version */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  display: flex;
  flex-direction: column;
  
}

.section3 {
  padding: 20px;
  background-color: #FCC100;
  justify-content: center;
  align-items: center;
}

.section2 {
  padding: 40px 20px;
  background-color: rgb(219, 219, 219);
  text-align: center;
}

.section1 {
  background: linear-gradient(to bottom, #993366, #330019);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 100vh; /* Full height */
  position: relative;
  overflow: hidden; /* Ensure the overlay image doesn't overflow the section */
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30%; /* Adjust as needed */
  height: auto;
  z-index: 2; /* Ensure the logo is above the background and overlay */
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../images/first_slide.png') no-repeat center center;
  background-size: cover;
  opacity: 1; /* Adjust the opacity as needed */
  z-index: 1; /* Ensure the background overlay is behind other content */
}

.storelogos{
  height: 50px;
}

.navbar {
  display: flex;
  gap: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: transform 0.3s ease-in-out;
  z-index: 2; /* Ensure the navbar is above the background and overlay */
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: 300; /* Light version */
}

.menu-icon,
.close-icon {
  display: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 2; /* Ensure the icons are above the background and overlay */
}


.feature-blocks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.feature-block {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  transform: translateY(50px);
  opacity: 0;
}

.feature-block.active {
  transform: translateY(0);
  opacity: 1;
}

.feature-icon {
  font-size: 48px;
  color: #993366;
  margin-bottom: 20px;
}

.feature-block ul {
  list-style: none;
  padding: 0;
}

.feature-block li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 10px 0;
}

.check-icon {
  margin-right: 10px;
  color: #28a745;
}

@media (max-width: 768px) {
    .navbar {
    flex-direction: column;
    gap: 10px;
    top: 0;
    right: 0;
    height: 100%;
    width: 200px;
    background-color: #330019;
    padding: 20px;
    position: fixed;
    transform: translateX(100%);
  }

  .navbar.open {
    transform: translateX(0);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .close-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .logo {
    width: 70%; /* Adjust for mobile view */
    top: 10px;
    left: 10px;
  }
  
  .storelogos{
    height: 40px;
  }
  .section1 {
    background: linear-gradient(to bottom, #993366, #330019);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 80vh; /* Full height */
    position: relative;
    overflow: hidden; /* Ensure the overlay image doesn't overflow the section */
  }
  
  .background-overlay {
    position: absolute;
    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/back_mobile.png') no-repeat center center;
    background-size: contain;
    opacity: 1; /* Adjust the opacity as needed */
    z-index: 1; /* Ensure the background overlay is behind other content */
  }
}
