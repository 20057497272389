/* src/components/Footer.css */
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap:20px;
    background-color: #333;
    color: white;
    padding: 10px;
    bottom: 0;
    width: 100%;
    position:fixed;
    
  }
  
  .footer-item {
    cursor: pointer;
    font-size: 10;
  }
  
  .footer-link {
    color: white;
    text-decoration: none;
    font-size: 10;
  }
  .footer-link a {
    font-size: 10;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
    }
  
    .footer-item {
      margin: 0;
    }
  }
  